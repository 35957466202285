'use client';

import { useAuthStore } from '@/stores/auth-store-provider';
import { Plural } from '@lingui/macro';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Stack, { StackProps } from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useMemo } from 'react';
import { useLifescoreHistory } from '../api/read';
import {
  LifescoreType,
  lifescoreDayRanges,
  lifescorePastHistoryLabels
} from '../assessments/constants/labels';
import LifescoreHistoryGraph from '../assessments/history/graph';
import { getLifescoresAverage } from '../get-lifescores-average';
import { LifescoreGauge } from './lifescore-gauge';
import { ViewHistoryLink } from './view-history-link';
import { useIsFetching } from '@tanstack/react-query';
import { getLifescoreHistoryQueryKey } from '../api/constants';

export type LifescoreHistoryCardProps = StackProps & {
  lifescoreType: LifescoreType;
};

export function LifescoreHistoryCard({
  lifescoreType,
  ...props
}: LifescoreHistoryCardProps) {
  const authStore = useAuthStore();
  const userId = authStore.get.userId();
  const isFetching = useIsFetching({
    queryKey: getLifescoreHistoryQueryKey(
      userId ?? '',
      lifescoreType,
      lifescoreDayRanges[lifescoreType]
    )
  });

  const { data: lifescores, isLoading } = useLifescoreHistory(
    lifescoreType,
    lifescoreDayRanges[lifescoreType]
  );

  const scoreAmount = lifescores?.length || 0;

  const lifescoresAverage = useMemo(() => {
    if (!lifescores || lifescores.length === 0) return 0;
    return getLifescoresAverage(lifescores, 'all', lifescoreType);
  }, [lifescores, lifescoreType]);

  const mostRecentLifescorePercentChange = useMemo(() => {
    if (!lifescores || lifescores.length < 2) return null;

    const mostRecentLifescore = lifescores[lifescores.length - 1];
    const previousLifescore = mostRecentLifescore.previousLifescore;

    if (!previousLifescore) return null;

    const percentDifference = Math.round(
      ((mostRecentLifescore.averageScorePercentage -
        previousLifescore.averageScorePercentage) /
        previousLifescore.averageScorePercentage) *
        100
    );

    return (
      <Typography
        fontWeight={700}
        variant="caption"
        color={percentDifference >= 0 ? 'success.dark' : 'error.dark'}
        pl={1}
      >
        {percentDifference}%
        {percentDifference >= 0 ? (
          <ArrowDropUpIcon sx={{ ml: -0.5 }} color="inherit" />
        ) : (
          <ArrowDropDownIcon sx={{ ml: -0.5 }} color="inherit" />
        )}
      </Typography>
    );
  }, [lifescores]);
  return (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      alignItems={{ xs: 'flex-start', md: 'center' }}
      spacing={2}
      p={2}
      {...props}
    >
      <Stack alignItems="center" spacing={3}>
        <Stack direction="column" alignItems="center" justifyContent="center">
          {isLoading ? (
            <>
              <Skeleton variant="circular" width={80} height={80} />
              <Typography variant="caption">
                <Skeleton width={40} variant="text" />
              </Typography>
            </>
          ) : (
            <>
              <LifescoreGauge value={lifescoresAverage ?? 0} />
              {mostRecentLifescorePercentChange}
            </>
          )}
        </Stack>
        <Stack direction="column" spacing={0}>
          <Typography
            variant="subtitle2"
            color="text.secondary"
            sx={{ textWrap: 'nowrap' }}
          >
            {lifescorePastHistoryLabels[lifescoreType]}
          </Typography>
          <Typography fontSize="120%" variant="body1" fontWeight={600}>
            {isLoading ? (
              <Skeleton width={80} />
            ) : (
              <Plural
                id="life-scores.card.score.text"
                value={Number(scoreAmount)}
                one="1 Score"
                other="# Scores"
              />
            )}
          </Typography>
          <ViewHistoryLink lifescoreType={lifescoreType} />
        </Stack>
      </Stack>
      <Box flex={1} width={{ xs: '100%', md: 'auto' }}>
        <LifescoreHistoryGraph
          lifescores={lifescores}
          lifescoreType={lifescoreType}
          lifescoresIsLoading={isLoading}
          lifescoresFailedToLoad={false}
          containerStyles={() => ({ background: 'none', padding: 0 })}
          chartContainerStyles={(theme) => ({
            height: '14rem',
            margin: {
              xs: '-2rem -3rem -2rem -1rem',
              md: '-2rem -3rem -2rem 0'
            }
          })}
          chartStyles={() => ({ top: 0 })}
        />
      </Box>
    </Stack>
  );
}
