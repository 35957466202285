'use client';

import { useOnUpdateDashboardStreak } from '@/api/dashboard/use-on-update-dashboard-streak';
import { GROWTH_COINS_DASHBOARD_STREAK } from '@/api/dashboard/utils';
import { GrowthCoinIcon } from '@/assets/icons/growth-coins/growth-coin';
import dayjs from 'dayjs';
import { CurrentStreakCount } from './current-streak-count';
import { useAuthStore } from '@/stores/auth-store-provider';
import Skeleton from '@mui/material/Skeleton';
import { usePopupState, bindDialog } from 'material-ui-popup-state/hooks';
import { Suspense } from 'react';
import {
  DashboardStreakDates,
  DashboardStreakDatesProps
} from './dashboard-streak-dates';
import { FireIcon } from '@/assets/icons/dashboard/fire-icon';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import { CloseButton } from '@/components/close-button';
import { Plural, Trans } from '@lingui/macro';
import Button from '@mui/material/Button';
import ChevronRightOutlined from '@mui/icons-material/ChevronRightOutlined';
import Chip from '@mui/material/Chip';
import { GrowthDayLogo } from '@/assets/logos/growthday';

export function DashboardStreakModal({
  date,
  month,
  weekDates
}: DashboardStreakDatesProps & { month: string }) {
  const authStore = useAuthStore();
  const dialogState = usePopupState({ variant: 'dialog' });
  const userId = authStore.useTracked.userId() ?? '';
  const lastStreakDate = dayjs
    .tz(weekDates[0])
    .subtract(1, 'day')
    .format('YYYY-MM-DD');

  useOnUpdateDashboardStreak(userId, lastStreakDate, month, (streak) => {
    if (streak.lastActiveDate === date) {
      dialogState.open();
    }
  });

  const currentStreakCount = (
    <Suspense fallback={<Skeleton width={20} />}>
      <CurrentStreakCount date={date} weekDates={weekDates} />
    </Suspense>
  );

  return (
    <Dialog maxWidth="sm" fullWidth {...bindDialog(dialogState)}>
      <DialogContent>
        <CloseButton
          onClick={dialogState.close}
          sx={{ position: 'absolute', top: 8, right: 8 }}
        />
        <Stack
          py={{ xs: 2, md: 4, lg: 6 }}
          direction="column"
          alignItems="center"
          spacing={3}
        >
          <GrowthDayLogo />
          <Box position="relative">
            <FireIcon
              sx={{ fontSize: 10, position: 'absolute', top: 0, right: 0 }}
            />
            <FireIcon
              sx={{ fontSize: 16, position: 'absolute', top: 0, left: 0 }}
            />
            <FireIcon sx={{ fontSize: 72 }} />
          </Box>
          <Stack direction="column">
            <Divider />
            <DashboardStreakDates date={date} weekDates={weekDates} />
            <Divider />
          </Stack>
          <Stack textAlign="center" direction="column">
            <Typography variant="h6" fontWeight={600}>
              <Trans id="dashboard.streak-celebration-title">
                You hit a {currentStreakCount} Day Streak!
              </Trans>
            </Typography>
            <Typography>
              <Trans id="dashboard.streak-celebration-subtitle">
                Keep building the momentum
              </Trans>
            </Typography>
          </Stack>
          <Chip
            icon={<GrowthCoinIcon fontSize="small" />}
            label={
              <Plural
                id="dashboard.streak-celebration-growth-coin"
                value={GROWTH_COINS_DASHBOARD_STREAK}
                one="+# Growth Coin"
                other="+# Growth Coins"
              />
            }
          />
          <Button
            onClick={dialogState.close}
            size="large"
            fullWidth
            sx={{ maxWidth: 320 }}
          >
            <Trans id="dashboard.streak-celebration-continue">Continue</Trans>
          </Button>
          <Button
            href="/leaderboard/streaks"
            size="large"
            fullWidth
            variant="outlined"
            sx={{ maxWidth: 320 }}
            endIcon={<ChevronRightOutlined />}
          >
            <Trans id="dashboard.streak-celebration-visit-leaderboard">
              View Leaderboard
            </Trans>
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
