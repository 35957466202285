import { SvgIcon, SvgIconProps } from '@mui/material';

export function GrowthDayLogo({ sx, ...props }: SvgIconProps) {
  return (
    <SvgIcon
      color="secondary"
      {...props}
      sx={[
        {
          height: {
            xs: 24,
            sm: 36
          },
          width: {
            xs: 128,
            sm: 192
          }
        },
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
      viewBox="0 0 463 87"
      fill="none"
    >
      <path d="M143.557 45.5962V41.8497H150.407V70.842C150.407 80.6362 144.099 86.6497 133.688 86.6497C129.031 86.6497 123.928 85.3827 120.64 83.0403L123.113 77.2802C126.28 79.2854 129.94 80.378 133.688 80.4376C140.064 80.4376 143.578 76.924 143.578 71.1365V68.6297C141.236 71.6228 137.722 73.1844 133.195 73.1844C126.072 73.1844 118.75 67.1983 118.75 57.1095C118.75 47.0208 126.072 41.0415 133.195 41.0415C137.701 41.0415 141.215 42.6373 143.557 45.5962ZM125.75 57.1095C125.75 62.3491 129.174 66.7736 134.955 66.7736C140.434 66.7736 144.16 62.5751 144.16 57.1095C144.16 51.6439 140.407 47.4454 134.928 47.4454C129.174 47.4454 125.729 51.8426 125.729 57.0821L125.75 57.1095Z" />
      <path d="M182.077 41.9182L180.447 48.6508C178.828 47.8968 177.062 47.5086 175.276 47.5139C170.947 47.5139 167.858 50.5754 167.858 55.8766V72.9994H161.057V41.8497H167.803V45.3633C169.858 42.4044 173.043 41.0415 176.851 41.0415C178.634 40.9894 180.409 41.2872 182.077 41.9182Z" />
      <path d="M219 57.4383C219 66.7736 211.843 73.835 202.213 73.835C192.583 73.835 185.46 66.7736 185.46 57.4383C185.46 48.1029 192.583 41.0415 202.213 41.0415C211.843 41.0415 219 48.0961 219 57.4383ZM192.453 57.4383C192.453 63.3285 196.652 67.4243 202.213 67.4243C207.775 67.4243 212.007 63.3148 212.007 57.4383C212.007 51.5617 207.775 47.4454 202.213 47.4454C196.652 47.4454 192.46 51.548 192.46 57.4383H192.453Z" />
      <path d="M260.951 65.6708L268.307 41.8496H275.396L265.081 73.0199H256.965L249.253 49.548L241.541 73.0405H233.555L223.24 41.8702H230.493L237.815 65.5612L245.493 41.8702H253.205L260.951 65.6708Z" />
      <path d="M303.779 48.0344H291.841V61.6025C291.841 65.801 294.053 67.4243 296.786 67.4243C299.032 67.2972 301.202 66.566 303.067 65.3079L305.765 70.7461C302.959 72.7296 299.613 73.8074 296.176 73.8351C289.122 73.8351 284.985 70.0269 284.985 61.7327V48.0344H278.773V41.8702H284.985V32.4253H291.834V41.8565H303.772L303.779 48.0344Z" />
      <path d="M318.908 45.5619C321.408 41.9867 325.251 41.0415 328.408 41.0415C335.826 41.0415 341.065 46.1509 341.065 53.4315V73.02H334.237V55.7123C334.237 50.4727 331.538 47.5482 326.983 47.5482C322.586 47.5482 318.915 50.4453 318.915 55.7671V73.0406H312.066V26.1926H318.915L318.908 45.5619Z" />
      <path d="M373.105 44.7538V26.1721H381.564V73.0201H373.277V69.5407C372.145 70.9407 370.703 72.0577 369.065 72.8031C367.427 73.5486 365.637 73.9019 363.839 73.8351C355.154 73.8351 348.586 66.712 348.586 57.2397C348.586 47.7674 355.147 40.6443 363.832 40.6443C368.065 40.6443 371.058 42.3086 373.105 44.7538ZM357.263 57.2123C357.263 62.0067 360.421 65.9038 365.393 65.9038C370.243 65.9038 373.53 62.1916 373.53 57.2123C373.53 52.233 370.243 48.5276 365.393 48.5276C360.421 48.555 357.263 52.4248 357.263 57.2397V57.2123Z" />
      <path d="M421.515 73.0201H413.221V69.5407C412.09 70.9407 410.648 72.0577 409.009 72.8031C407.371 73.5486 405.581 73.9019 403.783 73.8351C395.098 73.8351 388.53 66.712 388.53 57.2397C388.53 47.7674 395.098 40.6443 403.783 40.6443C405.585 40.5778 407.377 40.9348 409.016 41.6865C410.656 42.4383 412.096 43.5639 413.221 44.973V41.4868H421.515V73.0201ZM397.214 57.2671C397.214 62.0615 400.372 65.9586 405.344 65.9586C410.194 65.9586 413.481 62.2464 413.481 57.2671C413.481 52.2878 410.194 48.5824 405.344 48.5824C400.372 48.555 397.249 52.4248 397.249 57.2397L397.214 57.2671Z" />
      <path d="M438.268 86.1636H428.967L439.994 66.3012L426.398 41.4731H436.028L444.678 57.6781L453.075 41.4731H462.377L438.268 86.1636Z" />
      <path d="M6.67788 54.6646H0.575317V60.7055H6.67788V54.6646Z" />
      <path d="M82.8331 54.6646H14.116V60.7055H82.8331V54.6646Z" />
      <path d="M6.59572 67.2666H0.493164V73.3075H6.59572V67.2666Z" />
      <path d="M82.7442 67.2666H14.027V73.3075H82.7442V67.2666Z" />
      <path d="M69.4569 41.6305C69.2778 38.7536 68.6492 35.9226 67.5939 33.2403L81.4291 25.2063L78.3196 19.953L64.7789 27.8843C63.057 25.3187 60.918 23.0592 58.4503 21.1996L66.4706 7.87119L61.2722 4.748L53.245 18.0901C50.4527 16.8128 47.4663 16.0118 44.4096 15.7203V0.58374H38.355V15.7888C35.3351 16.1496 32.3959 17.0082 29.6567 18.3298L21.4377 4.7206L16.2461 7.85064L24.465 21.5489C22.1834 23.3467 20.1981 25.4914 18.5816 27.9048L5.12998 20.0284L2.05473 25.2816L15.8009 33.2609C14.7549 35.9378 14.1332 38.7617 13.9585 41.6305H0.575317V47.6645H82.8332V41.6305H69.4569ZM62.5255 41.6305H20.746C20.7389 41.5851 20.7389 41.5389 20.746 41.4935C20.8726 38.7077 21.5539 35.9751 22.7499 33.456C23.946 30.9368 25.6328 28.6817 27.7115 26.8227C29.2317 25.471 30.9387 24.3453 32.7799 23.4803C36.4257 21.7723 40.4867 21.1521 44.4761 21.6939C48.4656 22.2357 52.2141 23.9166 55.2723 26.535C57.4505 28.394 59.2227 30.6814 60.4788 33.2548C61.7349 35.8283 62.4479 38.6326 62.5735 41.4935C62.5651 41.5415 62.5489 41.5877 62.5255 41.6305Z" />
    </SvgIcon>
  );
}
