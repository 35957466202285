'use client';

import type { RumPublicApi } from '@datadog/browser-rum-core';
import { useQuery } from '@tanstack/react-query';

// Note that environment variables cannot be destructured for client-side usage
// due to the way Next.js handles environment variable exposure. Client-side
// environment variables must be explicitly accessed through `process.env`
// with the appropriate `NEXT_PUBLIC_` prefix.
const DATADOG_APPLICATION_ID = process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID;
const DATADOG_CLIENT_TOKEN = process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN;
const APP_ENV = process.env.NEXT_PUBLIC_APP_ENV || 'dev';
const NODE_ENV = process.env.NODE_ENV;

const enabled = Boolean(
  NODE_ENV === 'production' && DATADOG_APPLICATION_ID && DATADOG_CLIENT_TOKEN
);

declare global {
  interface Window {
    datadogRum: RumPublicApi | undefined;
  }
}

/**
 * This hook uses `useQuery` from React Query to dynamically import the Datadog Rum SDK
 * and attach it to the global `window` object. The Datadog Rum SDK instance is fetched
 * asynchronously and is returned as part of the query result.
 *
 * @returns An object containing the Datadog Rum SDK instance or any loading or error state information.
 *
 * @example
 * ```typescript
 * // Example usage in a React component
 * import { useDatadogRumSdk } from '@/hooks/use-datadog-rum-sdk';
 *
 * function MyComponent() {
 *   const { data: datadogRum, isLoading, isError } = useDatadogRumSdk();
 *
 *   if (isLoading) return <div>Loading Datadog Rum SDK...</div>;
 *   if (isError) return <div>Error loading Datadog Rum SDK.</div>;
 *
 *   return <div>Datadog Rum SDK is ready to use!</div>;
 * }
 * ```
 */
export function useDatadogRumSdk() {
  return useQuery({
    queryKey: ['datadog-rum'],
    queryFn: async () => {
      const { datadogRum } = await import('@datadog/browser-rum');
      window.datadogRum = datadogRum;
      if (window.datadogRum) {
        window.datadogRum.init({
          applicationId: DATADOG_APPLICATION_ID!,
          clientToken: DATADOG_CLIENT_TOKEN!,
          site: 'datadoghq.com',
          service: 'elrond-web',
          env: APP_ENV,
          sessionSampleRate: 100,
          sessionReplaySampleRate: 100,
          trackUserInteractions: true,
          trackResources: true,
          trackLongTasks: true,
          trackViewsManually: true,
          defaultPrivacyLevel: 'mask-user-input'
        });
      }
      return window.datadogRum;
    },
    enabled,
    staleTime: Infinity
  });
}
