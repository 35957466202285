import type { Schema } from '@/schema';
import { OverridePathToArray } from '@/types';
import { SelectionSet } from 'aws-amplify/data';

// Parent selection set and types is used for case like courses, have children like chapter and lessons.
// This entity type itself does not contain any media, and is called as parent entity, while the children may have assets
export const parentEntitySelectionSet = [
  // entity fields
  'id',
  'entityType',
  'title',
  'description',
  'order',
  'rootEntityId',
  'startTime',
  'validUntil',
  'requiredEntitlements',
  'enforceEntitlementsAt',
  'images.*',
  'shareContent.*',
  'assets.id',
  'assets.locator',
  'assets.title',
  'assets.description',
  'assets.assetType',
  'duration',

  // entity children relations fields
  'children.entities.id',
  'children.entities.entityType',
  'children.entities.title',
  'children.entities.description',
  'children.entities.order',
  'children.entities.rootEntityId',
  'children.entities.images.*',
  'children.entities.shareContent.*',
  'children.entities.assets.id',
  'children.entities.assets.locator',
  'children.entities.assets.title',
  'children.entities.assets.description',
  'children.entities.assets.assetType',

  // entity children - children relations fields
  'children.entities.children.entities.id',
  'children.entities.children.entities.entityType',
  'children.entities.children.entities.title',
  'children.entities.children.entities.description',
  'children.entities.children.entities.order',
  'children.entities.children.entities.rootEntityId',
  'children.entities.children.entities.images.*',
  'children.entities.children.entities.shareContent.*',
  'children.entities.children.entities.assets.id',
  'children.entities.children.entities.assets.locator',
  'children.entities.children.entities.assets.title',
  'children.entities.children.entities.assets.description',
  'children.entities.children.entities.assets.assetType'
] as const;
export type ParentEntityItem = OverridePathToArray<
  SelectionSet<Schema['Entity']['type'], typeof parentEntitySelectionSet>,
  | 'images'
  | 'shareContent.images'
  | 'assets.id'
  | 'assets.locator'
  | 'assets.title'
  | 'assets.description'
  | 'assets.assetType'
  | 'children.entities.images'
  | 'children.entities.shareContent.images'
  | 'children.entities.children.entities.images'
  | 'children.entities.children.entities.shareContent.images'
>;

// Entity selection set and types is used for all entities with media, like lessons, replays, daily fire,
// featured video etc.
export const entitySelectionSet = [
  'id',
  'entityType',
  'title',
  'description',
  'duration',
  'order',
  'rootEntityId',
  'images.*',
  'shareContent.*',
  'assets.id',
  'assets.locator',
  'assets.title',
  'assets.assetType',
  'startTime',
  'liveState',
  'requiredEntitlements',
  'enforceEntitlementsAt',
  'socialProof.*'
] as const; 

export type EntityItem = OverridePathToArray<
  SelectionSet<Schema['Entity']['type'], typeof entitySelectionSet>,
  'images' | 'shareContent.images'
>;

// List selection set and types is used for listing entity cards
export const listEntitySelectionSet = [
  'id',
  'entityType',
  'title',
  'order',
  'rootEntityId',
  'liveState',
  'requiredEntitlements',
  'images.*'
] as const;
export type ListEntityItem = OverridePathToArray<
  SelectionSet<Schema['Entity']['type'], typeof listEntitySelectionSet>,
  'images'
>;

export type SearchEntityItem = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<Schema['searchEntities']['returnType']>['hits']
    >[number]
  >['_source']
>;

export type SearchEntitiesResponseType = Schema['searchEntities']['returnType'];
