'use client';

import { listDashboardStreaksQuery } from '@/api/dashboard/list-streaks';
import {
  getCurrentStreakCount,
  isInBetween
} from '../utils/get-current-streak-count';
import { useGraphqlClient } from '@/libs/amplify/client';
import { useAuthStore } from '@/stores/auth-store-provider';
import { useSuspenseQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useMemo } from 'react';

export function useSuspenseStreakInfo(date: string, weekDates: string[]) {
  const authStore = useAuthStore();
  const userId = authStore.useTracked.userId() ?? '';
  const graphqlClient = useGraphqlClient();
  const lastStreakDate = dayjs
    .tz(weekDates[0])
    .subtract(1, 'day')
    .format('YYYY-MM-DD');

  const { data: dashboardStreaks } = useSuspenseQuery(
    listDashboardStreaksQuery(graphqlClient, userId, lastStreakDate)
  );

  const currentStreakCount = useMemo(
    () => getCurrentStreakCount(dashboardStreaks, date),
    [dashboardStreaks, date]
  );

  const streakDates = useMemo(
    () =>
      // Filter the weekDates array to find dates that fall within any streak.
      // This would mean, that date was part of some streak and should be
      // marked as completed
      weekDates.filter((weekDate) => {
        return dashboardStreaks?.some((streak) =>
          isInBetween(weekDate, streak.startDate, streak.lastActiveDate)
        );
      }),
    [dashboardStreaks, weekDates]
  );

  return {
    currentStreakCount,
    streakDates
  };
}
