import {
  Lifescore,
  Lifescores,
  LifescoreType
} from './assessments/constants/labels';

/**
 *  A function that calculates the average {metricName} for a set of Lifescores.
 *  @param lifescores - A set of Lifescores
 *  @param metricName - The metric/category to calculate the average for. For example, "clarity", "health", ...etc. (This includes the "all" metric which maps to "averageScorePercentage" in the Lifescore models)
 *  @param lifescoreType - The type of Lifescores in the set, can be "daily" or "monthly" ("montly" is for whole life)
 */
export function getLifescoresAverage(
  lifescores: Lifescores,
  metricName: string,
  lifescoreType: LifescoreType
): number {
  if (!lifescores.length) return 0;

  const metricKey =
    metricName === 'all' ? 'averageScorePercentage' : metricName + 'Score';

  const scoreSum = lifescores.reduce(
    (acc, lifescore) =>
      acc +
      ((lifescore?.[metricKey as unknown as keyof Lifescore] as number) ?? 0),
    0
  );

  if (metricName === 'all') {
    return Math.round(scoreSum / lifescores.length);
  }

  const MAX_SCORE = lifescoreType === 'daily' ? 5 : 10;
  const scoreSumPercentage = (scoreSum / MAX_SCORE) * 100;

  return Math.round(scoreSumPercentage / lifescores.length);
}
