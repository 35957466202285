import { GraphqlClient } from '@/libs/amplify/types';
import { DefaultError } from '@tanstack/query-core';
import { UseSuspenseQueryOptions } from '@tanstack/react-query';
import {
  listDashboardStreaksQueryKey,
  ListDashboardStreaksQueryKey
} from './query-options';
import { DashboardStreak, dashboardStreakSelectionSet } from './types';

export function listDashboardStreaksQuery(
  graphqlClient: GraphqlClient,
  owner: string,
  lastStreakDate: string
): UseSuspenseQueryOptions<
  DashboardStreak[],
  DefaultError,
  DashboardStreak[],
  ListDashboardStreaksQueryKey
> {
  const queryKey = listDashboardStreaksQueryKey(owner, lastStreakDate);
  return {
    queryKey,
    queryFn: async () => {
      if (!owner) {
        return [];
      }
      const { data, errors } =
        await graphqlClient.models.DashboardStreak.listDashboardStreakByOwnerAndLastActiveDate(
          {
            owner,
            lastActiveDate: { ge: lastStreakDate }
          },
          {
            sortDirection: 'DESC',
            selectionSet: dashboardStreakSelectionSet
          }
        );
      if (errors?.length) {
        console.log(errors);
      }
      return (data ?? []) as DashboardStreak[];
    }
  };
}
