import {
  Asset,
  AssetType,
  EntityImageType,
  RecommendedItem
} from '@/__generated__/API';
import {
  EntityItem,
  ListEntityItem,
  ParentEntityItem,
  SearchEntityItem
} from '@/api/entities/types';
import compact from 'lodash-es/compact';
import sortBy from 'lodash-es/sortBy';

export type FindEntityImagesEntityType =
  | ListEntityItem
  | ParentEntityItem
  | EntityItem
  | SearchEntityItem
  | RecommendedItem
  | undefined
  | null;

export function findEntityImages(
  entity: FindEntityImagesEntityType,
  ...imageTypes: EntityImageType[]
) {
  if (entity?.images && Array.isArray(entity.images)) {
    return sortBy(
      compact(entity.images).filter((image) =>
        imageTypes.includes(image.imageType as EntityImageType)
      ),
      (item) => imageTypes.indexOf(item.imageType as EntityImageType)
    );
  }
  return [];
}

export function findEntityAssets(
  entity: EntityItem | ParentEntityItem | undefined | null,
  ...assetTypes: AssetType[]
) {
  return findAssetTypes(entity?.assets as unknown as Asset[], ...assetTypes);
}

export function findAssetTypes(assets: Asset[], ...assetTypes: AssetType[]) {
  if (Array.isArray(assets)) {
    return sortBy(
      compact(assets).filter((asset) =>
        assetTypes.includes(asset.assetType as unknown as AssetType)
      ),
      (item) => assetTypes.indexOf(item.assetType as unknown as AssetType)
    );
  }
  return [];
}
