import { useGraphqlClient } from '@/libs/amplify/client';
import { useQuery } from '@tanstack/react-query';
import { getReferralProfileQuery } from './get-referrals';
import { useAuthStore } from '@/stores/auth-store-provider';

export function useReferralCode() {
  const graphqlClient = useGraphqlClient();
  const authStore = useAuthStore();
  const userId = authStore.useTracked.userId() ?? '';

  const { data: referralProfile } = useQuery(
    getReferralProfileQuery(graphqlClient, userId)
  );

  return referralProfile?.referralCode;
}
