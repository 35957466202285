'use client';

import { DailyLifescore, MonthlyLifescore } from '@/__generated__/API';
import { useGraphqlClient } from '@/libs/amplify/client';
import { useAuthStore } from '@/stores/auth-store-provider';
import { snackbarStore } from '@/stores/snackbar-store';
import { useLingui } from '@lingui/react';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { LifescoreType, Lifescores } from '../assessments/constants/labels';
import {
  getLifescoreHistoryQueryKey,
  lifescoreHistoryQueryKeyPrefix
} from './constants';
import { throwGraphqlError } from '@/utils/throw-graphql-error';

export const useLifescoreHistory = (
  lifescoreType: LifescoreType,
  dayRangeFilter: number
) => {
  const authStore = useAuthStore();
  const graphqlClient = useGraphqlClient();
  const userId = authStore.get.user()?.sub;
  const { i18n } = useLingui();

  const owner = userId ? `${userId}::${userId}` : '';

  return useSuspenseQuery({
    queryKey: getLifescoreHistoryQueryKey(
      userId ?? '',
      lifescoreType,
      dayRangeFilter
    ),
    queryFn: async () => {
      if (!owner) {
        return [];
      }
      const fromDate = dayjs()
        .startOf('day')
        .subtract(dayRangeFilter, 'day')
        .toISOString();

      const args = {
        owner,
        createdAt: { ge: fromDate }
      };
      const opts = {
        limit: 1000,
        sortDirection: 'ASC' as 'ASC' | 'DESC',
        nextToken: null
      };

      const getLifescores =
        lifescoreType === 'daily'
          ? graphqlClient.models.DailyLifescore
              .listDailyLifescoreByOwnerAndCreatedAt
          : graphqlClient.models.MonthlyLifescore
              .listMonthlyLifescoreByOwnerAndCreatedAt;

      let data: Lifescores = [];
      let nextToken: string | undefined | null;

      // Grab all data at once for displaying on graph.
      // Activity cards will be paginated on client.
      try {
        do {
          const res = await (getLifescores as any)(args, {
            ...opts,
            nextToken
          });
          data = [...data, ...(res.data as Lifescores)];
          nextToken = res.nextToken;
        } while (nextToken);
      } catch (e) {
        snackbarStore.set.create('error', i18n.t('error.something-went-wrong'));
      }

      data.map((lifescore, index, lifescores) => {
        // Store a reference to previous lifescore to compute
        // percent increase/decrease relative to previous lifescore
        if (index !== 0) {
          lifescore.previousLifescore = lifescores[index - 1];
        }

        return lifescore;
      });

      return data;
    }
  });
};

export const getLifescoreByParentIdQueryKey = <
  T extends LifescoreType = LifescoreType
>(
  lifescoreType: T,
  parentId: string
) => [lifescoreHistoryQueryKeyPrefix, { lifescoreType, parentId }];

export function useGetLifescoreByParentId<
  T extends LifescoreType = LifescoreType
>(lifescoreType: T, parentId: string, enabled?: boolean) {
  const authStore = useAuthStore();
  const userId = authStore.get.userId();
  const graphqlClient = useGraphqlClient();

  return useQuery({
    queryKey: getLifescoreByParentIdQueryKey(lifescoreType, parentId),
    queryFn: async () => {
      const filter = { parentId, owner: { eq: `${userId}::${userId}` } };
      const options = { limit: 1000 };

      const { data, errors } =
        lifescoreType === 'daily'
          ? await graphqlClient.models.DailyLifescore.listDailyLifescoreByParentIdAndOwner(
              filter,
              options
            )
          : await graphqlClient.models.MonthlyLifescore.listMonthlyLifescoreByParentIdAndOwner(
              filter,
              options
            );

      throwGraphqlError(errors);

      return (
        (data?.[0] as T extends 'daily' ? DailyLifescore : MonthlyLifescore) ||
        null
      );
    },
    enabled
  });
}
