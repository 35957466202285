'use client';

import { Trans } from '@lingui/macro';
import { ChevronRight } from '@mui/icons-material';
import Link from '@mui/material/Link';
import { LifescoreType } from '../assessments/constants/labels';

export type ViewHistoryLinkProps = {
  lifescoreType: LifescoreType;
};

export function ViewHistoryLink({ lifescoreType }: ViewHistoryLinkProps) {
  const trackHistoryVisit = () => {
    rudderanalytics.track('Click', {
      lifescoreType,
      element_id: 'lifescore-history-link',
      type: 'LINK'
    });
  };

  return (
    <Link
      mt={1}
      id="lifescore-history-link"
      data-testid="lifescore-history-link"
      href={`/life-scores/assessments/history?initialLifescoreType=${lifescoreType}`}
      color="text.primary"
      onClick={trackHistoryVisit}
      fontWeight="normal"
      sx={{ textWrap: 'nowrap' }}
    >
      <Trans id="life-scores.card.history.link">View History</Trans>
      <ChevronRight />
    </Link>
  );
}
