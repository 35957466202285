'use client';

import { EntityType } from '@/__generated__/API';
import { getEntityQuery } from '@/api/entities/get-entity';
import { EntityCardSkeleton } from '@/app/[lang]/(shell)/components/dashboard/dashboard-cards-skeleton';
import { EntityPlayback } from '@/app/[lang]/(shell)/learn/components/playback/entity-playback';
import { isMediaComplete } from '@/app/[lang]/(shell)/learn/utils/is-media-complete';
import { ReadMore } from '@/components/read-more';
import useStableCallback from '@/hooks/use-stable-callback';
import { useGraphqlClient } from '@/libs/amplify/client';
import { RenderHTML } from '@/libs/editor/html';
import { useAuthStore } from '@/stores/auth-store-provider';
import { CardHeader } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { MediaEvent, MediaTimeUpdateEventDetail } from '@vidstack/react';
import { useSearchParams } from 'next/navigation';
import { useCallback, useMemo, useRef } from 'react';
import { useIntersectionObserver } from 'usehooks-ts';
import { DashboardEntityWrapper } from './entity-wrapper';
import { DashboardCardProps } from './types';
import {
  dashboardCardItemCompleted,
  sendBrazeDashboardTaskStartedEvent
} from '../utils/send-braze-dashboard-events';

// Media play time needed to be played by the user to mark the
// task as completed
const DEFAULT_COMPLETION_DURATION = 2 * 60; // 2 minutes (in seconds)

export function EntityCard({
  card,
  isComplete,
  onComplete
}: DashboardCardProps) {
  const searchParams = useSearchParams();
  const graphqlClient = useGraphqlClient();
  const entityId = card.entityId ?? '';
  const cardId = card.id;
  const queryClient = useQueryClient();
  const authStore = useAuthStore();
  const userId = authStore.useTracked.userId();
  const isInProgressRef = useRef(false);

  const { ref, isIntersecting } = useIntersectionObserver({
    initialIsIntersecting: false,
    freezeOnceVisible: true
  });

  const { data: entity, isLoading } = useQuery({
    ...getEntityQuery(graphqlClient, { id: entityId }),
    enabled: isIntersecting
  });

  const onTimeUpdate = useStableCallback(
    async (
      detail: MediaTimeUpdateEventDetail,
      event: MediaEvent<MediaTimeUpdateEventDetail>
    ) => {
      if (!event.target.duration || isInProgressRef.current) return;

      let mediaComplete: boolean;

      // Check if the media duration exceeds the default completion duration
      if (event.target.duration > DEFAULT_COMPLETION_DURATION) {
        mediaComplete = detail.currentTime > DEFAULT_COMPLETION_DURATION;
      } else {
        // For shorter media, calculate the completion based on the percentage played
        mediaComplete = isMediaComplete(
          (detail.currentTime / event.target.duration) * 100
        );
      }

      if (mediaComplete) {
        isInProgressRef.current = true;
        try {
          await onComplete(cardId);
        } catch (e) {
          isInProgressRef.current = false;
        }
      }
    }
  );

  const onPlay = useStableCallback(() => {
    if (entity?.id && entity?.entityType) {
      const isCompleted = dashboardCardItemCompleted(
        queryClient,
        userId ?? '',
        'ENTITY',
        entity.id
      );
      if (!isCompleted) {
        sendBrazeDashboardTaskStartedEvent(
          queryClient,
          userId ?? '',
          'ENTITY',
          entity.entityType as EntityType,
          entity.id
        );
      }
    }
  });

  const deepLinkId = useCallback(() => {
    switch (entity?.entityType) {
      case EntityType.DAILYFIRE:
        return 'daily-fire';
      case EntityType.FEATURED_VIDEO:
        return 'featured-video';
      default:
        return undefined;
    }
  }, [entity?.entityType]);

  const autoPlayParam = searchParams.get('autoplay');

  const autoPlay = useMemo(() => {
    switch (entity?.entityType) {
      case EntityType.DAILYFIRE:
        return autoPlayParam === 'dailyfire';
      case EntityType.FEATURED_VIDEO:
        return autoPlayParam === 'featured-video';
      default:
        return undefined;
    }
  }, [entity?.entityType, autoPlayParam]);

  return (
    <div id={deepLinkId()} ref={ref}>
      {!isIntersecting || isLoading ? (
        <EntityCardSkeleton />
      ) : (
        entity && (
          <DashboardEntityWrapper entity={entity}>
            <EntityPlayback
              autoPlay={autoPlay}
              entity={entity}
              onPlay={onPlay}
              onTimeUpdate={onTimeUpdate as any}
            />
            {entity.entityType !== EntityType.DAILYFIRE && (
              <CardHeader
                title={
                  <Typography variant="h6" fontWeight={600}>
                    {entity.title}
                  </Typography>
                }
                subheader={
                  <ReadMore lines={2}>
                    <RenderHTML>{entity.description}</RenderHTML>
                  </ReadMore>
                }
              />
            )}
          </DashboardEntityWrapper>
        )
      )}
    </div>
  );
}
