'use client';

import { getLeaderboardQueryKey } from '@/api/leaderboard/query-options';
import useStableCallback from '@/hooks/use-stable-callback';
import { useGraphqlClient } from '@/libs/amplify/client';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { produce } from 'immer';
import { useCallback, useEffect } from 'react';
import { listDashboardStreaksQueryKey } from './query-options';
import { DashboardStreak, dashboardStreakSelectionSet } from './types';

export function useOnUpdateDashboardStreak(
  owner: string,
  lastStreakDate: string,
  month: string,
  callback?: (item: DashboardStreak) => void
) {
  const queryClient = useQueryClient();
  const queryKey = listDashboardStreaksQueryKey(owner, lastStreakDate);
  const leaderboardQueryKey = getLeaderboardQueryKey(month);
  const graphqlClient = useGraphqlClient();
  const stableCallback = useStableCallback(callback);

  const onSubscribe = useCallback(
    (streak: DashboardStreak) => {
      if (streak?.owner === owner) {
        queryClient.setQueryData<DashboardStreak[]>(queryKey, (data) => {
          if (data) {
            return produce(data, (draft) => {
              const index = draft.findIndex(
                (draftStreak) => draftStreak.id === streak.id
              );

              if (index > -1) {
                if (dayjs(streak.updatedAt).isAfter(draft[index].updatedAt)) {
                  draft[index] = streak;
                }
              } else {
                draft.push(streak);
              }
            });
          }
          return data;
        });
        queryClient.invalidateQueries({ queryKey: leaderboardQueryKey });
        stableCallback?.(streak);
      }
    },
    [owner, queryClient, queryKey, leaderboardQueryKey, stableCallback]
  );

  useEffect(() => {
    if (owner) {
      const options = {
        selectionSet: dashboardStreakSelectionSet,
        filter: {
          lastActiveDate: { ge: lastStreakDate }
        }
      };

      const createSubscription =
        graphqlClient.models.DashboardStreak.onCreate(options).subscribe(
          onSubscribe
        );

      const updateSubscription =
        graphqlClient.models.DashboardStreak.onUpdate(options).subscribe(
          onSubscribe
        );

      return () => {
        createSubscription.unsubscribe();
        updateSubscription.unsubscribe();
      };
    }
  }, [graphqlClient, lastStreakDate, owner, onSubscribe]);
}
