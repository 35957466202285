'use client';

import { lifescoresStore } from '@/stores/lifescore-store';
import { useLingui } from '@lingui/react';
import { Box, useTheme } from '@mui/material';
import { alpha, Theme } from '@mui/material/styles';
import { LineChart } from '@mui/x-charts/LineChart';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useCallback, useMemo } from 'react';
import { Lifescore, Lifescores, LifescoreXY } from '../constants/labels';

dayjs.extend(utc);
dayjs.extend(timezone);

type ThemeStyles = (theme: Theme) => Record<string, any>;

type HistoryGraphProps = {
  lifescores: Lifescores | undefined;
  lifescoresIsLoading: boolean;
  lifescoresFailedToLoad: boolean;
  containerStyles?: ThemeStyles;
  chartContainerStyles?: ThemeStyles;
  chartStyles?: ThemeStyles;
  lifescoreType: string;
  HistoryGraphHeader?: React.ReactNode;
  HistoryGraphFooter?: React.ReactNode;
};

export default function LifescoreHistoryGraph({
  lifescores,
  lifescoresIsLoading,
  lifescoresFailedToLoad,
  containerStyles = () => ({}),
  chartContainerStyles = () => ({}),
  chartStyles = () => ({}),
  HistoryGraphHeader,
  lifescoreType,
  HistoryGraphFooter
}: HistoryGraphProps) {
  const theme = useTheme();
  const { i18n } = useLingui();

  const lifescoreDataIsChanging = lifescoresStore.use.lifescoreDataIsChanging();
  const lifescoreMetricFilter =
    lifescoresStore.use.lifescoreMetricHistoryFilter();

  const getLifescoreMetric = useCallback(
    (lifescore: Lifescore, metric: string): number => {
      if (metric === 'all') return lifescore.averageScorePercentage;

      const metricValue = lifescore[
        `${metric}Score` as keyof Lifescore
      ] as number;
      const normalizationFactor = lifescoreType === 'daily' ? 5 : 10;
      return Math.round((metricValue / normalizationFactor) * 100);
    },
    [lifescoreType]
  );

  const computeXYCoordinates = useCallback(
    (data: Lifescores, metric: string): LifescoreXY[] =>
      data.map((entry) => ({
        x: dayjs.utc(entry.createdAt).tz().startOf('day').toDate(),
        y: getLifescoreMetric(entry, metric)
      })),
    [getLifescoreMetric]
  );

  const filterHighestPerDay = (data: LifescoreXY[]): LifescoreXY[] => {
    const highestByDay: Record<string, LifescoreXY> = {};
    data.forEach(({ x, y }) => {
      const dateKey = x.toISOString();
      if (!highestByDay[dateKey] || y > highestByDay[dateKey].y) {
        highestByDay[dateKey] = { x, y };
      }
    });
    return Object.values(highestByDay);
  };

  const allCoordinates = useMemo(
    () => computeXYCoordinates(lifescores ?? [], lifescoreMetricFilter),
    [computeXYCoordinates, lifescores, lifescoreMetricFilter]
  );

  const dailyHighCoordinates = useMemo(() => {
    return filterHighestPerDay(allCoordinates)
      .filter((entry) => entry.x !== undefined && entry.y !== undefined)
      .map((entry) => ({
        x: entry.x,
        y: entry.y
      }));
  }, [allCoordinates]);

  const dateToScoresMap = useMemo(() => {
    const map: Record<string, number[]> = {};
    allCoordinates.forEach(({ x, y }) => {
      const dateKey = dayjs(x).startOf('day').toISOString();
      map[dateKey] = map[dateKey] ? [...new Set([...map[dateKey], y])] : [y];
    });
    return map;
  }, [allCoordinates]);

  const valueFormatter = (
    value: number | null,
    { dataIndex }: { dataIndex: number }
  ): string => {
    if (value === null) return '';
    const currentDate = dailyHighCoordinates[dataIndex]?.x;
    if (!currentDate) return '';
    const dateKey = dayjs.utc(currentDate).tz().startOf('day').toISOString();
    const scores = dateToScoresMap[dateKey] || [];

    const label =
      lifescoreType === 'daily'
        ? i18n.t('life-scores.graph.daily-score-label', {
            count: scores.length,
            message: '{count, plural, one {Daily Score} other {Daily Scores}}'
          })
        : i18n.t('life-scores.graph.score-label', {
            count: scores.length,
            message: '{count, plural, one {Score} other {Scores}}'
          });

    return `${label}: ${scores.map((score) => `${score}%`).join(', ')}`;
  };

  return (
    <Box
      sx={{
        background: theme.palette.background.paper,
        padding: '2rem',
        ...containerStyles(theme)
      }}
    >
      <Box
        sx={{
          height: '400px',
          position: 'relative',
          borderRadius: '10px',
          ...chartContainerStyles(theme)
        }}
      >
        {HistoryGraphHeader}
        <LineChart
          tooltip={{
            trigger: dailyHighCoordinates.length ? 'item' : 'none'
          }}
          slotProps={{
            loadingOverlay: {
              message: i18n.t('life-scores.history.loadingOverlayMessage', {
                message: 'Loading data...'
              })
            },
            noDataOverlay: {
              message: i18n.t('life-scores.history.noDataOverlayMessage', {
                message: 'No data to display'
              })
            }
          }}
          dataset={lifescoresFailedToLoad ? [] : dailyHighCoordinates}
          loading={lifescoresIsLoading}
          yAxis={[{ min: 0, max: 100 }]}
          skipAnimation
          xAxis={[
            {
              dataKey: 'x',
              scaleType: 'time',
              tickMinStep: 4 * 24 * 60 * 60 * 1000,
              valueFormatter: (date) => dayjs.tz(date).format('MMM D')
            }
          ]}
          series={[
            {
              dataKey: 'y',
              color: theme.palette.primary.main,
              area: true,
              baseline: 'min',
              valueFormatter
            }
          ]}
          grid={{ horizontal: true }}
          sx={{
            position: 'absolute',
            top: '2rem',
            '.MuiChartsAxis-directionY line, .MuiChartsAxis-directionX .MuiChartsAxis-tick':
              {
                stroke: 'none'
              },
            '.MuiChartsAxis-directionX line': {
              stroke: theme.palette.text.secondary
            },
            tspan: {
              fill: theme.palette.text.secondary
            },
            '.MuiAreaElement-root': {
              fill: 'url(#lifescore-history-graph-gradient)'
            },
            ...chartStyles(theme)
          }}
        >
          <defs>
            <linearGradient
              id="lifescore-history-graph-gradient"
              x1="0"
              x2="0"
              y1="0"
              y2="1"
            >
              <stop
                offset="0%"
                style={{ stopColor: alpha(theme.palette.primary.main, 0.3) }}
              />
              <stop
                offset="100%"
                style={{ stopColor: alpha(theme.palette.primary.main, 0) }}
              />
            </linearGradient>
          </defs>
        </LineChart>
      </Box>
      {HistoryGraphFooter}
    </Box>
  );
}
