'use client';

import useStableCallback from '@/hooks/use-stable-callback';
import {
  ChecklistCardSkeleton,
  EntityCardSkeleton,
  JournalCardSkeleton,
  LifescoreCardSkeleton
} from './dashboard-cards-skeleton';
import sortBy from 'lodash-es/sortBy';
import { Suspense, useEffect } from 'react';
import { DashboardCardItemType } from '@/__generated__/API';
import {
  getDashboardCardQuery,
  getDashboardProgressQuery
} from '@/api/dashboard/get-dashboard';
import { ComponentType, useMemo } from 'react';
import { DashboardTimelineItem } from './dashboard-timeline-item';
import Timeline from '@mui/lab/Timeline';
import { EntityCard } from './cards/entity-card';
import { JournalCard } from './cards/journal-card';
import { ChecklistCard } from './cards/checklist-card';
import { LifescoreCard } from './cards/lifescore-card';
import { DashboardCardProps } from './cards/types';
import { useUpdateDashboardProgress } from '@/api/dashboard/update-progress';
import { useAuthStore } from '@/stores/auth-store-provider';
import { keyBy } from 'lodash-es';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { useGraphqlClient } from '@/libs/amplify/client';
import { dashboardDevToolStore } from '@/stores/dashboard-dev-tool-store';
import { isUserDev } from '@/utils/acl';

const cardComponentMap: Record<
  DashboardCardItemType,
  ComponentType<DashboardCardProps>
> = {
  [DashboardCardItemType.ENTITY]: EntityCard,
  [DashboardCardItemType.JOURNAL]: JournalCard,
  [DashboardCardItemType.CHECKLIST]: ChecklistCard,
  [DashboardCardItemType.LIFESCORE]: LifescoreCard
};

const skeletonComponentMap: Record<DashboardCardItemType, ComponentType> = {
  [DashboardCardItemType.ENTITY]: EntityCardSkeleton,
  [DashboardCardItemType.JOURNAL]: JournalCardSkeleton,
  [DashboardCardItemType.CHECKLIST]: ChecklistCardSkeleton,
  [DashboardCardItemType.LIFESCORE]: LifescoreCardSkeleton
};

export type DashboardCardsProps = {
  date: string;
};

export function DashboardCards({ date }: DashboardCardsProps) {
  const authStore = useAuthStore();
  const graphqlClient = useGraphqlClient();
  const user = authStore.useTracked.user();
  const userId = user?.id || '';
  const isDevUser = isUserDev(user);

  const { data: dashboardCard } = useSuspenseQuery(
    getDashboardCardQuery(graphqlClient, date)
  );

  const cards = useMemo(
    () => sortBy(dashboardCard?.children, 'order').filter((card) => card.type),
    [dashboardCard]
  );

  const { data: progress, isLoading: isProgressLoading } = useQuery(
    getDashboardProgressQuery(graphqlClient, userId, date)
  );

  const { mutateAsync: updateProgress, isPending: isProgressUpdating } =
    useUpdateDashboardProgress(userId, date, cards);

  const progressMap = useMemo(
    () => keyBy(progress?.children, 'parentId'),
    [progress]
  );

  const onComplete = useStableCallback(async (itemId: string) => {
    const isComplete = !!progressMap[itemId]?.completeTime;
    if (!isComplete && !isProgressUpdating && !isProgressLoading) {
      await updateProgress(itemId);
    }
  });

  useEffect(() => {
    // required to make the dashboard dev tool date sync with checklists page
    if (isDevUser) {
      dashboardDevToolStore.set.date(date);
    }
  }, [date, isDevUser]);

  return (
    <div>
      <Timeline sx={{ px: 0, mt: 0, ml: { xs: 0, lg: -4 } }}>
        {cards?.map((card) => {
          const Component = cardComponentMap[card.type!];
          const Skeleton = skeletonComponentMap[card.type!];
          const isComplete = !!progressMap[card.id]?.completeTime;
          return (
            <DashboardTimelineItem checked={isComplete} key={card.id}>
              <Suspense fallback={<Skeleton />}>
                <Component
                  card={card}
                  isComplete={isComplete}
                  onComplete={onComplete}
                />
              </Suspense>
            </DashboardTimelineItem>
          );
        })}
      </Timeline>
    </div>
  );
}
