import {
  GrowthCoinTransactionSource,
  GrowthCoinTransactionSourceEvent
} from '@/__generated__/API';
import {
  ShouldNotAddCoinsCallback,
  useCreateGrowthCoinTransactionMutation
} from '@/api/growth-coin-transactions/create';
import { keyBy } from 'lodash-es';
import {
  DashboardCardItem,
  DashboardProgress,
  DashboardProgressItem
} from './types';
import dayjs from 'dayjs';

export function getNewOrUpdatedProgress({
  previousProgress,
  itemId,
  cards,
  date,
  owner
}: {
  previousProgress: DashboardProgress | null | undefined;
  itemId: string;
  cards: DashboardCardItem[];
  date: string;
  owner: string;
}) {
  const now = dayjs().toISOString();
  const progressMap = keyBy(previousProgress?.children, 'parentId');

  const children: DashboardProgressItem[] = cards.map((item) => {
    const progressItem: DashboardProgressItem = {
      parentId: item.id,
      completeTime: progressMap[item.id]?.completeTime || null
    };

    if (progressItem.parentId === itemId) {
      progressItem.completeTime = now;
    }

    return progressItem;
  });

  const updatedProgress = {
    date,
    owner,
    completeTime: previousProgress?.completeTime || null,
    children
  } as DashboardProgress;

  const allItemsCompleted = children.every((item) => item.completeTime);

  if (allItemsCompleted) {
    updatedProgress.completeTime = now;
  }

  return updatedProgress;
}

const shouldNotAddCoinsCallback: ShouldNotAddCoinsCallback = ({
  mostRecentTransactionBySourceEvent
}) => {
  if (!mostRecentTransactionBySourceEvent) return false;

  const mostRecentTransactionCreatedAt = dayjs
    .utc(mostRecentTransactionBySourceEvent.createdAt)
    .tz();

  if (dayjs.tz().isSame(mostRecentTransactionCreatedAt, 'day')) {
    console.error(
      `Daily coin transaction rejected. Previous successful transaction by source event ${mostRecentTransactionBySourceEvent.sourceEvent} id:`,
      mostRecentTransactionBySourceEvent.id
    );
    return true;
  }
  return false;
};

export const GROWTH_COINS_DASHBOARD_STREAK = 5;

export function grantCoinsOnCompletion(
  createGrowthCoinTransaction: ReturnType<
    typeof useCreateGrowthCoinTransactionMutation
  >['mutate'],
  cardId: string,
  currentProgress: DashboardProgress,
  previousProgress?: DashboardProgress | null
) {
  const hasCompletedDailyStreak =
    !previousProgress?.completeTime && currentProgress?.completeTime;
  if (hasCompletedDailyStreak) {
    createGrowthCoinTransaction({
      shouldNotAddCoinsCallback,
      coinsAdded: GROWTH_COINS_DASHBOARD_STREAK,
      source: GrowthCoinTransactionSource.DASHBOARD,
      sourceEvent: GrowthCoinTransactionSourceEvent.GROWTH_LOOP_COMPLETED,
      analyticsElementId: `dashboard-card-${cardId}`
    });
  }
}
