'use client';

import { CommentParentType } from '@/__generated__/API';
import { DashboardCardItem } from '@/api/dashboard/types';
import { LifescoreCardSkeleton } from '@/app/[lang]/(shell)/components/dashboard/dashboard-cards-skeleton';
import { useListJournalsByParentId } from '@/app/[lang]/(shell)/journal/api/list';
import { JournalInlineEditor } from '@/app/[lang]/(shell)/journal/components/journal-inline-editor';
import { useGetLifescoreByParentId } from '@/app/[lang]/(shell)/life-scores/api/read';
import { AssessmentTopScoreSectionContainer } from '@/app/[lang]/(shell)/life-scores/assessments/components/daily-assessment-top-section-container';
import { LifescoreType } from '@/app/[lang]/(shell)/life-scores/assessments/constants/labels';
import { useLifescoreState } from '@/app/[lang]/(shell)/life-scores/assessments/hooks/use-lifescore-state';
import { LifescoreHistoryCard } from '@/app/[lang]/(shell)/life-scores/components/lifescore-history-card';
import {
  Carousel,
  CarouselNavigation,
  NavigationState
} from '@/components/carousel';
import { useAuthStore } from '@/stores/auth-store-provider';
import { lifescoresStore } from '@/stores/lifescore-store';
import { Trans } from '@lingui/macro';
import { Box, CardContent, CardHeader, Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { useQueryClient } from '@tanstack/react-query';
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { SwiperClass, SwiperRef } from 'swiper/react';
import { useIntersectionObserver } from 'usehooks-ts';
import {
  dashboardCardItemCompleted,
  sendBrazeDashboardTaskStartedEvent
} from '../utils/send-braze-dashboard-events';
import { DashboardCardWrapper } from './card-wrapper';
import './lifescore-card.scss';
import { DashboardCardProps } from './types';

type LifescoreCreateCardProps = {
  lifescoreType: LifescoreType;
  parentId: string;
  card: DashboardCardItem;
  onNavStateChanged: (navState: NavigationState) => void;
};

function LifescoreCreateCard({
  lifescoreType,
  parentId,
  card,
  onNavStateChanged
}: LifescoreCreateCardProps) {
  const authStore = useAuthStore();
  const userId = authStore.useTracked.userId();
  const [activeIndex, setActiveIndex] = useState(0);
  const swiperRef = useRef<SwiperRef>(null) as MutableRefObject<SwiperRef>;
  const queryClient = useQueryClient();

  const {
    scores,
    currentStep,
    onScoreClick,
    scoresNames,
    habits,
    isPending,
    isSuccess
  } = useLifescoreState(lifescoreType, {
    parentId
  });

  const isLoading = isPending || isSuccess;

  const swiper = swiperRef.current?.swiper;

  useEffect(() => {
    const newIndex = currentStep - 1;
    if (swiper && activeIndex !== newIndex) {
      swiper.slideTo(newIndex, 0);
    }
  }, [currentStep, activeIndex, swiper]);

  const onSlideChange = (swiperClass: SwiperClass) => {
    const newIndex = swiperClass.activeIndex;
    const newStep = newIndex + 1;
    if (newStep === 2) {
      const isComplete = dashboardCardItemCompleted(
        queryClient,
        userId ?? '',
        'LIFESCORE'
      );
      if (!isComplete) {
        sendBrazeDashboardTaskStartedEvent(
          queryClient,
          userId ?? '',
          'LIFESCORE'
        );
      }
    }
    if (newStep !== currentStep) {
      lifescoresStore.set.assessmentStep(newStep);
    }
    setActiveIndex(newIndex);
  };

  const sliderScore = scores[scoresNames[activeIndex]];

  return (
    <Carousel
      autoHeight
      allowSlideNext={!!sliderScore}
      swiperRef={swiperRef}
      onSlideChange={onSlideChange}
      onNavStateChanged={onNavStateChanged}
      navigationSpeed={0}
      slidesPerViewPerBreakpoint={1}
      id={card.id}
      hideNavigation
      pagination={{ el: `.swiper-pagination-${card.id}`, type: 'bullets' }}
    >
      {scoresNames.map((scoreName) => {
        const habit = habits[scoreName];
        return (
          <Box key={scoreName} p={{ xs: 0, sm: 2, md: 4 }}>
            <AssessmentTopScoreSectionContainer
              hideIcon
              creatingLifescore={isLoading}
              habit={habit}
              lifescoreType={lifescoreType}
              onScoreClick={onScoreClick}
              scoreName={scoreName}
              StackProps={{ sx: { backgroundColor: 'transparent' } }}
            />
          </Box>
        );
      })}
    </Carousel>
  );
}

export function LifescoreCard({
  card,
  onComplete,
  isComplete
}: DashboardCardProps) {
  const { ref, isIntersecting } = useIntersectionObserver({
    initialIsIntersecting: false,
    freezeOnceVisible: true
  });
  const cardId = card.id;
  const parentId = `dashboard#${card.date}#${card.id}`;
  const lifescoreType: LifescoreType =
    card.assessmentType === 'MONTHLY' ? 'monthly' : 'daily';

  const { data: lifescore, isLoading } = useGetLifescoreByParentId(
    lifescoreType,
    parentId,
    isIntersecting
  );
  const journalParentId = lifescore ? `dashboard#${card.date}#${card.id}` : '';

  const { data: journalList } = useListJournalsByParentId(
    {
      parentId: journalParentId
    },
    isIntersecting
  );

  const journal = journalList?.[0];

  const title = (
    <Typography variant="h6" fontWeight={600}>
      <Trans id="dashboard.dailyReflectionTitle">Daily Reflection</Trans>
    </Typography>
  );

  const [navigationState, setNavigationState] = useState<NavigationState>({
    prev: 'disabled',
    next: 'disabled'
  });

  const onNavStateChanged = useCallback((navState: NavigationState) => {
    setNavigationState(navState);
  }, []);

  useEffect(() => {
    if (journal && lifescore) {
      onComplete(cardId);
    }
  }, [journal, lifescore, onComplete, cardId]);

  const showNavigation = !isLoading && !lifescore && !journalParentId;

  return (
    <div id="evening-reflection" ref={ref}>
      {!isIntersecting ? (
        <LifescoreCardSkeleton />
      ) : (
        <DashboardCardWrapper
          parentId={parentId}
          parentType={CommentParentType.ENTITY}
          commentsTitle={<CardHeader title={title} />}
        >
          <CardHeader
            className="lifescore-card-header"
            title={title}
            subheader={
              <Trans id="dashboard.lifescoreSubheader">
                Capture gratitude and score your habits
              </Trans>
            }
            action={
              showNavigation && (
                <Stack direction="row" position="relative">
                  <div
                    className={`swiper-pagination swiper-pagination-${card.id}`}
                  />
                  <CarouselNavigation
                    navState={navigationState}
                    enabled={!(isLoading || lifescore || parentId)}
                    carouselId={card.id}
                  />
                </Stack>
              )
            }
            sx={{ borderBottom: '1px solid', borderBottomColor: 'divider' }}
          />
          <CardContent>
            {isLoading ? (
              <Skeleton
                sx={{ height: '10rem', width: '100%', opacity: 0.5 }}
                variant="rounded"
              />
            ) : lifescore && journal ? (
              <Stack direction="column" spacing={3}>
                <LifescoreHistoryCard lifescoreType={lifescoreType} />
                <JournalInlineEditor
                  parentId={journalParentId}
                  customPrompt={card.customPrompt}
                  customPromptImage={card.image}
                  disableContentContainerStyles
                  {...(journal
                    ? {
                        hideDate: false,
                        hideJournalPrompts: false,
                        hideJournalCustomPrompt: true
                      }
                    : {})}
                />
              </Stack>
            ) : lifescore ? (
              <JournalInlineEditor
                parentId={journalParentId}
                customPrompt={card.customPrompt}
                customPromptImage={card.image}
                disableContentContainerStyles
                {...(journal
                  ? {
                      hideDate: false,
                      hideJournalPrompts: false,
                      hideJournalCustomPrompt: true
                    }
                  : {})}
              />
            ) : (
              <LifescoreCreateCard
                lifescoreType={lifescoreType}
                parentId={parentId}
                card={card}
                onNavStateChanged={onNavStateChanged}
              />
            )}
          </CardContent>
        </DashboardCardWrapper>
      )}
    </div>
  );
}
