import { Loading } from '@/components/loading';
import { absoluteFill, centeredContent } from '@/utils/styles';
import Box from '@mui/material/Box';
import { CircularProgressProps } from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

export function LifescoreGauge(props: CircularProgressProps) {
  return (
    <Box position="relative">
      <Loading size="5rem" variant="determinate" {...props} />
      <Box sx={[absoluteFill, centeredContent]}>
        <Typography color="primary.main" fontWeight={600} mr={-0.5}>
          {props.value}%
        </Typography>
      </Box>
    </Box>
  );
}
