import { getDashboardProgressQuery } from '@/api/dashboard/get-dashboard';
import { useCreateGrowthCoinTransactionMutation } from '@/api/growth-coin-transactions/create';
import { sendBrazeDashboardCompleteEvents } from '@/app/[lang]/(shell)/components/dashboard/utils/send-braze-dashboard-events';
import { useGraphqlClient } from '@/libs/amplify/client';
import { useDatadogRumSdk } from '@/libs/datadog/use-datadog-rum-sdk';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { DashboardCardItem, DashboardProgress } from './types';
import { getDashboardProgressQueryKey } from './query-options';
import { getNewOrUpdatedProgress, grantCoinsOnCompletion } from './utils';
import { throwGraphqlError } from '@/utils/throw-graphql-error';

export function useUpdateDashboardProgress(
  owner: string,
  date: string,
  cards: DashboardCardItem[]
) {
  const { data: datadogRum } = useDatadogRumSdk();
  const queryKey = getDashboardProgressQueryKey(owner, date);
  const graphqlClient = useGraphqlClient();
  const queryClient = useQueryClient();
  const { mutate: createGrowthCoinTransaction } =
    useCreateGrowthCoinTransactionMutation();

  let { data: previousProgress, refetch } = useQuery(
    getDashboardProgressQuery(graphqlClient, owner, date)
  );

  return useMutation({
    onMutate: async (itemId) => {
      await queryClient.cancelQueries({ queryKey });

      const updatedProgress = getNewOrUpdatedProgress({
        date,
        itemId,
        cards,
        owner,
        previousProgress
      });

      queryClient.setQueryData<DashboardProgress>(
        queryKey,
        () => updatedProgress
      );

      return { previousProgress, updatedProgress };
    },
    mutationFn: async (itemId: string) => {
      const previousProgress = (await refetch()).data;

      if (previousProgress?.completeTime) {
        throwGraphqlError([
          {
            message: 'Dashboard streak already completed'
          }
        ]);
      }

      const updatedProgress = getNewOrUpdatedProgress({
        date,
        itemId,
        cards,
        owner,
        previousProgress
      });

      queryClient.setQueryData<DashboardProgress>(
        queryKey,
        () => updatedProgress
      );

      const { data, errors } = previousProgress
        ? await graphqlClient.models.DashboardProgress.update(updatedProgress)
        : await graphqlClient.models.DashboardProgress.create(updatedProgress);

      throwGraphqlError(errors);

      return {
        previousProgress,
        updatedProgress: data as DashboardProgress
      };
    },
    onError: async (_error, _variables, context) => {
      try {
        const previousProgress = (await refetch()).data;
        queryClient.setQueryData(queryKey, previousProgress);
      } catch (e) {
        queryClient.setQueryData(queryKey, context?.previousProgress);
        await queryClient.invalidateQueries({ queryKey, refetchType: 'all' });
      }
    },
    onSuccess: (data, itemId) => {
      sendBrazeDashboardCompleteEvents(queryClient, owner, itemId, date);
      if (data?.previousProgress) {
        grantCoinsOnCompletion(
          createGrowthCoinTransaction,
          itemId,
          data.updatedProgress,
          data.previousProgress
        );
      }
      queryClient.setQueryData<DashboardProgress>(
        queryKey,
        data.updatedProgress
      );
    },
    scope: {
      id: 'dashboard-progress'
    }
  });
}
