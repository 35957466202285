import { GraphqlClient } from '@/libs/amplify/types';
import type { Schema } from '@/schema';
import type { DefaultError } from '@tanstack/query-core';
import { UseSuspenseQueryOptions } from '@tanstack/react-query';

export function getReferralProfileQueryKey(owner: string) {
  return ['GetReferralProfile', owner] as const;
}

export type GetReferralProfileQueryKey = ReturnType<
  typeof getReferralProfileQueryKey
>;

export function getReferralStatsQueryKey(owner: string, month?: string) {
  return ['GetReferralStats', owner, ...(month ? [{ month }] : [])] as const;
}

export type GetReferralStatsQueryKey = ReturnType<
  typeof getReferralStatsQueryKey
>;

export function getReferralEarningsSummaryQueryKey(
  owner: string,
  startDate?: string
) {
  return [
    'GetReferralEarningsSummary',
    owner,
    startDate ? { startDate } : {}
  ] as const;
}

export type GetReferralEarningsSummaryQueryKey = ReturnType<
  typeof getReferralEarningsSummaryQueryKey
>;

export type GetReferralProfile = Schema['ReferralProfile']['type'];
export type GetReferralStats = Schema['ReferralStats']['type'];
export type GetReferralEarningsSummary =
  Schema['ReferralEarningsSummary']['type'];

export function getReferralProfileQuery(
  graphqlClient: GraphqlClient,
  owner: string
): UseSuspenseQueryOptions<
  GetReferralProfile | null,
  DefaultError,
  GetReferralProfile | null,
  GetReferralProfileQueryKey
> {
  const queryKey = getReferralProfileQueryKey(owner);
  return {
    queryKey,
    queryFn: async () => {
      if (!owner) {
        return null;
      }
      const { data, errors } = await graphqlClient.queries.getReferralProfile();
      if (errors?.length) {
        console.error(errors);
      }
      return data as GetReferralProfile;
    }
  };
}

export function getMonthReferralStatsQuery(
  graphqlClient: GraphqlClient,
  owner: string,
  month?: string
): UseSuspenseQueryOptions<
  GetReferralStats | null,
  DefaultError,
  GetReferralStats | null,
  GetReferralStatsQueryKey
> {
  const queryKey = getReferralStatsQueryKey(owner, month);
  return {
    queryKey,
    queryFn: async () => {
      if (!owner) {
        return null;
      }
      const { data, errors } =
        await graphqlClient.queries.getMonthReferralStats({ month });
      if (errors?.length) {
        console.error(errors);
      }
      return data as GetReferralStats;
    }
  };
}

export function getReferralEarningsSummaryQuery(
  graphqlClient: GraphqlClient,
  owner: string,
  startDate?: string
): UseSuspenseQueryOptions<
  GetReferralEarningsSummary | null,
  DefaultError,
  GetReferralEarningsSummary | null,
  GetReferralEarningsSummaryQueryKey
> {
  const queryKey = getReferralEarningsSummaryQueryKey(owner, startDate);
  return {
    queryKey,
    queryFn: async () => {
      if (!owner) {
        return null;
      }
      const { data, errors } =
        await graphqlClient.queries.getReferralEarningsSummary({ startDate });
      if (errors?.length) {
        console.error(errors);
      }
      return data as GetReferralEarningsSummary;
    }
  };
}
